<template>
    <div class="parentBox">
        <el-row class="findFixed">
            <el-col :span="16" >
                <el-date-picker
                    v-model="beginMonth"
                    type="month"
                    :picker-options="pickerOptions"
                    placeholder="开始月份">
                </el-date-picker>
                <el-date-picker
                    v-model="endMonth"
                    type="month"
                    :picker-options="pickerOptions"
                    placeholder="结束月份">
                </el-date-picker>
                <el-select v-model="endStatus" placeholder="请选择">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="2" :offset='6'>
                <el-button type="primary" @click="handleSendReq(1)">查询</el-button>
            </el-col>
        </el-row>
        <div class="showDatas">
            <div>
               <Help  title='分佣收益'><p class="dataRedu">请勿据此开票，合计分成金额以结算对账单为准。</p></Help>
                <div class="dataBox">
                    <el-button class="btn" size="small" @click="exportExcel">下载</el-button>
                    <el-table
                        :data="tableList"
                        ref="outTable"
                        border
                        stripe
                        :max-height="520"
                        style="width: 100%;marginTop:20px;">
                        <el-table-column
                        prop="month"
                        label="时间"
                        width="180">
                        </el-table-column>
                        <el-table-column
                        prop="ruleName"
                        label="开发者名称"
                        width="180">
                        </el-table-column>
                        <el-table-column
                        prop="pretax_money"
                        label="预计收入（元）">
                        </el-table-column>
                        <el-table-column
                        prop="appendtax_money"
                        label="实际收入（元）">
                        </el-table-column>
                        <el-table-column
                        prop="status_txt"
                        label="状态"
                        width="180">
                        </el-table-column>
                    </el-table>
                </div>
                <Paging 
                    @changeSize='changeSize'
                    @changePage='changePage'
                    :total="total"
                    :returnPage1="returnPage1"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import InputCard from '../components/InputCard.vue'
    import Help from '../components/Help.vue'
    import FileSaver from 'file-saver'
    import XLSX from 'xlsx'
    import Paging from '../components/Paging.vue'
    import {getBrokerageData} from '../api/staticPages'
    export default {
        components: {
            InputCard,Help,Paging
        },
        data(){
            return {
                beginMonth:'',
                endMonth:'',
                currentPage:1,
                pageMsgCount:10,
                endStatus:'',
                returnPage1:0,
                total:10,
                options: [
                    {
                        value: 1,
                        label: '待结算'
                    }, {
                        value: 2,
                        label: '已结算'
                    }
                ],
                tableList:null,
                pickerOptions: {
                    disabledDate: (time) => {
                        let end = new Date()
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 30)
                        return time.getTime() > end
                    }
                },
            }
        },
        methods:{
            handleSendReq(num) {
                this.returnPage1+=num
                let reqData = {}
                let {beginMonth,endMonth,currentPage,pageMsgCount,endStatus} = this
                if(beginMonth){
                    var year_b = beginMonth.getFullYear()+'';
                    var month_b = beginMonth.getMonth()+1+'';
                    month_b = month_b.length > 1 ? month_b : '0'+ month_b
                    reqData.dtBegin = year_b+month_b
                }
                if(endMonth){
                    var year = endMonth.getFullYear()+'';
                    var month = endMonth.getMonth()+1+'';
                    month = month.length > 1 ? month : '0'+ month
                    reqData.dtEnd = year+month
                }
                reqData.page = currentPage
                reqData.pageSize = pageMsgCount
                reqData.status = endStatus
                const loading = this.$loading({
                    lock: true,
                    text: '加载中...',
                    spinner: 'el-icon-loading',
                    background: 'rgba(165, 165, 220, .1)'
                });
                getBrokerageData(reqData).then(res=>{
                    let {list,total} = res.data.data
                    this.tableList = list 
                    this.total = total
                    loading.close();
                }).catch(()=>{loading.close()})
            },
            changeSize(val){
                this.pageMsgCount = val
                this.handleSendReq(0)
            },
            changePage(val){
                this.currentPage = val
                this.handleSendReq(0)
            },
            exportExcel() {
                var wb = XLSX.utils.table_to_book(this.$refs.outTable.$el)
                var wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'array' })
                try {
                    FileSaver.saveAs(new Blob([wbout], { type: 'application/octet-stream' }), 'sheetjs.xls')
                } catch (e) { if (typeof console !== 'undefined') console.log(e, wbout) }
                return wbout
            },
        },
        created(){
            const loading = this.$loading({
                lock: true,
                text: '加载中...',
                spinner: 'el-icon-loading',
                background: 'rgba(165, 165, 220, .1)'
            });
            getBrokerageData().then(res=>{
                let {list,total} = res.data.data
                this.tableList = this.tableList || list 
                this.total = this.total === 10 ? total : this.total
                loading.close()
            }).catch(()=>{loading.close()})
        }
    }
</script>

<style lang="less" scoped>
    .parentBox{
        position: relative;
        background: white;
    }
    .findFixed{
        padding:20px;
        border-bottom:1px solid #dde;
        background: white;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 50px;
        .col-box{
            margin-right:30px;
        }
    }
    .el-date-editor--month{
        margin-right: 40px !important;
    }
    .showDatas{
        padding:20px;
        .dataBox{
            position:relative;
            .btn{
                position: absolute;
                top: -39px;
                right: 0;
                color: #898989 !important;
            }
        }
        .dataRedu{
            font-size: 14px;
            font-weight: normal;
            white-space: nowrap;
        }
    }
</style>